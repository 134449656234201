<template>
	<div class="about-view">
		<ByCancel @close="close"></ByCancel>
		<h3 class="title">關於點溡</h3>
		<div class="content">
			<p>好吃的美食在尖峰時段常常大排長龍...</p>
			<p>店家忙著做餐沒時間幫我點餐...</p>
			<p>大量紙本菜單寫完就丟很不環保...</p>
			<p>這些問題點溡都幫您解決了！</p>
			<p>簡便的點餐流程</p>
			<p>step1拿起手機，掃描QRcode</p>
			<p>step2瀏覽菜單，選擇想吃的餐點</p>
			<p>step3加入購物車</p>
			<p>step4送出訂單</p>
			<p>step5等待餐點完成、取餐</p>
			<p>透過點溡</p>
			<p>平台免抽成</p>
			<p>點餐免下載APP</p>
			<p>來店免排隊</p>
			<p>掃描QR code立即點餐</p>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	methods: {
		close() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="sass" scoped>
.about-view
  padding-bottom: 18px

.title
  position: absolute
  top: -14px
  left: 50%
  transform: translateX(-50%)

.content
  +fc
  flex-direction: column
  padding: 0 17px 30px
  margin-top: 15px
  width: 100%
  letter-spacing: 1px
  font-size: 15px
  line-height: 1.5
  text-align: justify

  > p
    width: 100%

.part
  margin: 25px 0 0
</style>
